<template>
  <v-row no-gutters>
    <v-col cols="12" md="7" lg="7" class="pa-4">  
      <v-card
        outlined
        tile
        class="mb-3"
      >
        <v-card-text :class="{'pa-0': $vuetify.breakpoint.mobile}">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  color="secondary"
                  class="blue-grey lighten-5"
                  dark
                >
                  mdi-account
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3">RESPONSABLE</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold primary--text" v-text="info.persona_contacto"></v-list-item-title>
                  </v-col>
                  <!-- <v-col cols="5">
                    <v-list-item-subtitle class="caption blue-grey--text text--darken-3">Vendedor</v-list-item-subtitle>
                    <v-list-item-title class="font-weight-bold primary--text" v-text="info.ven_des"></v-list-item-title>
                  </v-col> -->
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  color="secondary"
                  class="blue-grey lighten-5"
                >
                  mdi-map-marker
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle class="caption blue-grey--text text--darken-3">DIRECCIÓN</v-list-item-subtitle>
                <v-list-item-title class="font-weight-bold primary--text space-normal" v-text="info.direccion"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card
        outlined
        tile
      >
        <v-card-text :class="{'px-0': $vuetify.breakpoint.mobile}">
          <v-row class="px-4">
            <v-col cols="6">
              <v-icon small left color="blue-grey">mdi-text-box-check-outline</v-icon>
              <span class="caption blue-grey--text text--darken-3">RIF</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold primary--text" v-text="info.rif" />
            </v-col>
            <v-col cols="12" class="py-1 my-0">
              <v-divider />
            </v-col>
            <v-col cols="6">
              <v-icon small left color="blue-grey">mdi-text-box-check-outline</v-icon>
              <span class="caption blue-grey--text text--darken-3">Razón Social</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold primary--text" v-text="info.razon_social" />
            </v-col>
            <v-col cols="12" class="py-1 my-0">
              <v-divider />
            </v-col>
            <v-col cols="6">
              <v-icon small left color="blue-grey">mdi-email-check-outline</v-icon>
              <span class="caption blue-grey--text text--darken-3">Email</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold primary--text" v-text="info.correo" />
            </v-col>
            <v-col cols="12" class="py-1 my-0">
              <v-divider />
            </v-col>
            <v-col cols="6">
                <v-icon small left color="blue-grey">mdi-cellphone-iphone</v-icon>              
                <span class="caption blue-grey--text text--darken-3">Teléfono</span>           
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold primary--text" v-text="info.telefono" />
            </v-col>
            <v-col cols="12" class="py-1 my-0">
              <v-divider />
            </v-col>          
            <v-col cols="6">
              <v-icon small left color="blue-grey">mdi-map-marker-outline</v-icon>
              <span class="caption blue-grey--text text--darken-3">Latitud/Longitud actual</span>
            </v-col>
            <v-col cols="6">
            <span class="font-weight-bold primary--text" v-if="info.lat">LAT {{info.lat}} / LNG {{info.lng}}</span>
            <span class="font-weight-bold red--text" v-else>S/A</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col> 
    <v-col cols="12" md="5" lg="5" :class="{'pa-4': $vuetify.breakpoint.mobile}">
      <gmap-map
        :center="center"
        :zoom="15"
        :options="{
          streetViewControl: false,
          rotateControl: true,
          disableDefaultUI: false,
        }"
        :style="styleMap"
      >
        <gmap-marker
          :position="markers.position"
          :clickable="false"
          :draggable="false"
          :icon="require('@/assets/Markers/marker_m1_verde.png')"
        />                    
      </gmap-map>
    </v-col>
  </v-row>       
</template>
<script>
export default {
  name: 'TabDetails',
  components: { 
  },
  props:{
    info: {
      type: Object,
      default: () => ({})
    }, 
    loading: {
      type: Boolean,
      default: true
    }
  },
  data:() =>({
    toggle_edit_day: false,
    center: { lat: 8.955582, lng: -65.3220065 },
    markers: {},
  }),
  mounted() {
    if(this.info.lat != null && this.info.lng != null) {
      this.center = {
        lat: this.info.lat ? parseFloat(this.info.lat.trim()) : null,
        lng: this.info.lng ? parseFloat(this.info.lng.trim()) : null,
      };
      this.markers = { position: this.center };
    }
    else
      this.geolocate();
  },

  methods:{
    actionEdit() {
      this.toggle_edit_day = true;
      this.$vuetify.goTo(9999);
    },
    geolocate () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    },
  },
  computed:{
    styleMap() {
      return {
        width: '100%',
        height: this.$vuetify.breakpoint.mobile ? '100vh' : '100%',
        'margin-right': '12px'
      }
    }
  }
}
</script>
<style lang="sass">
.space-normal
  white-space: normal !important
  overflow: visible !important
</style>